import './HomeScreen.css';
import {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import YouTube from "react-youtube";
import { PlayerVars, YouTubeProps } from "react-youtube";

type HomeScreenProps = {
  showcaseMode?: boolean
}


export const youtubeOptions: PlayerVars = {
  autoplay: 1,
  rel: 0,
  modestbranding: 1,
  controls: 1,
  showinfo: 0,
  playsinline: 1,
}

type NavButtonProps = {
  image: string,
  callback: Function
};

const NavButton = ({image, callback}: NavButtonProps) =>(
  <button className="Nav_Button" onClick={() => callback()} >
    <div className="Nav_ButtonBlue" ></div>
    <img src={`./media/${image}`} />
  </button>
)

function HomeScreen({ showcaseMode = false }: HomeScreenProps) {

  const history = useHistory();

  // ------------------------------------------------
  //  Video playback
  // ------------------------------------------------
  const [currentVideo, setCurrentVideo] = useState('');

  function handleVideoEnd() {
    setCurrentVideo('');

    // Reset the timeout when a video ends
    resetIdleTimeout();
  }

  function handleVideoRequest(value: string) {
    setCurrentVideo(value);

    // Clear the idle timeout so the user doesn't get kicked out mid video
    clearIdleTimeout();
  }

  // ------------------------------------------------
  //  Idle timeout
  // ------------------------------------------------
  const [idleTimeout, setIdleTimeout] = useState(setTimeout(() => {}, -1));

  // This kicks in whenever the user enters the home screen, or a video ends
  function resetIdleTimeout() {

    if(!showcaseMode)
      return;

    clearTimeout(idleTimeout);

    let timeout = setTimeout(() => {
      history.push('/loop');
    }, 1000 * 60 * 3);

    setIdleTimeout(timeout);
  }

  // Clears the timeout and sets it to an empty timeout
  // This is used for when the user clicks on a video and we don't want them being kicked out mid video
  function clearIdleTimeout() {
    clearTimeout(idleTimeout);
    let timeout = setTimeout(() => {}, -1)
    setIdleTimeout(timeout);
  }

  useEffect(() => {
    resetIdleTimeout();
    // return () => clearTimeout(idleTimeout);
  }, [])

  function goToIdle() {
    clearTimeout(idleTimeout)
    history.push(showcaseMode ? '/idle' : '/loop');
  }


  // ------------------------------------------------
  //  Escape closes video
  // ------------------------------------------------
  const handleEscape = useCallback((event) => {
    if(event.keyCode === 27) {
      handleVideoEnd();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEscape, false);

    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, []);



  // ------------------------------------------------
  //  Render
  // ------------------------------------------------
  return (<>
    <div className="HomeScreen_Root">

      <header onClick={goToIdle}>
        <img width={128} src="./media/banner.png" />
      </header>

      <div className="HomeScreen_Nav">
        <NavButton
          image={"3_1_napis_predstavitev_galerije.png"}
          callback={() => handleVideoRequest("sKfOfRSy7Rw")}
        />
        <NavButton
          image={"4_depoji.png"}
          callback={() => handleVideoRequest("xHiCRKzig0w")}
        />
        <NavButton
          image={"5_pedagoski_program.png"}
          callback={() => handleVideoRequest("eOdAImJhXeY")}
        />
        <NavButton
          image={"6_tehnicna_sluzba.png"}
          callback={() => handleVideoRequest("2kPqkdKPX6o")}
        />
        <NavButton
          image={"5_1_umeščenost.png"}
          callback={() => handleVideoRequest("C0eWAtCJbNM")}
        />
        <NavButton
          image={"6_1_podobe.png"}
          callback={() => handleVideoRequest("VnAZuonHEoE")}
        />
        <NavButton
          image={"7_1_prenova.png"}
          callback={() => handleVideoRequest("HvcBt_ls280")}
        />
      </div>

      <div className="HomeScreen_Promo">
        <img onClick={goToIdle} src="./media/1_ozadje-02.png" />
      </div>
    </div>


    <div className={`HomeScreen_VideoRoot ${currentVideo != '' ? ' active' : ''}`}  >
      <div className={`HomeScreen_VideoBackdrop`}></div>
      <div className={`HomeScreen_VideoContainer`}>
        {/*<video*/}
        {/*  className="requestedVideoPlayback"*/}
        {/*  src={currentVideo == '' ? '' : `https://brotherants.com/video/${currentVideo}.mp4`}*/}
        {/*  autoPlay={true}*/}
        {/*  controls={true}*/}
        {/*  controlsList="nodownload nofullscreen"*/}
        {/*  onEnded={handleVideoEnd}*/}
        {/*/>*/}

        <div className={`requestedVideoPlayback_Root  ${currentVideo === '' ? ' hidden' : ''} `}>
          <YouTube
            className={`requestedVideoPlayback ${currentVideo === '' ? ' hidden' : ''}`}
            videoId={currentVideo}
            opts={{
              playerVars: youtubeOptions
            }}
            onEnd={handleVideoEnd}
          />
        </div>


        <div className="HomeScreen_Overlay" >
          <button className="HomeScreen_VideoGoBack" onClick={() => handleVideoEnd()} >
            <img width={23} src="./media/close.png" />
          </button>
        </div>
      </div>
    </div>
  </>);
}

export default HomeScreen;
