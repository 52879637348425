import React from 'react';
import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import HomeScreen from "./components/HomeScreen";
import InactivityVideoLoop from "./components/InactivityVideoLoop";

const routes = [

  // Standard mode - For desktop and mobile
  // /loop does NOT actually loop anymore due to... unforeseen consequences
  { path: '/loop', Component: InactivityVideoLoop },
  { path: '/', Component: HomeScreen },

  // Showcase mode - For showcase on TV/large devices
  { path: '/idle', Component: InactivityVideoLoop, props: { showcaseMode: true } },
  { path: '/gallery', Component: HomeScreen, props: { showcaseMode: true } },

]

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Container className="container"  >
          {routes.map(({ path, Component, props }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <div className="page">
                    <Component {...props} />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
          </Container>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
