import React, {useEffect, useRef} from 'react';
import { useHistory } from "react-router-dom";
import './InactivityVideoLoop.css';
import {PlayerVars} from "react-youtube";
import YouTube from "react-youtube";
import { YouTubeProps } from "react-youtube";

type InactivityVideoLoopProps = {
  showcaseMode?: boolean
}

export const youtubeOptions: PlayerVars = {
  autoplay: 1,
  rel: 0,
  modestbranding: 1,
  controls: 1,
  showinfo: 0,
  playsinline: 1,
  loop: 1,
  mute: 1,
  playlist: 'OjkhCo86YIw'
}


function InactivityVideoLoop({ showcaseMode = false }: InactivityVideoLoopProps) {

  // Redirects
  const history = useHistory();
  function handleLoopClick() {
    history.push(showcaseMode ? '/gallery' : '/');
  }

  function handleOnEnd() {
    if(!showcaseMode) {
      handleLoopClick();
    }
  }

  // Loop stuff
  const loopRef = useRef<HTMLVideoElement>(null);
  // useEffect(() => {
  //   loopRef?.current?.addEventListener('ended', () => {
  //     let video = loopRef?.current;
  //     if(video) {
  //       video.currentTime = 0.016;
  //       video.play();
  //     }
  //   });
  //
  // }, [])

  return (
    <div className="InactivityVideoLoop_Root">
      <div className="InactivityVideoLoop_Inner" onClick={handleLoopClick} >
        {/*<iframe*/}
        {/*  className="InactivityVideoLoop_Video"*/}
        {/*  width="560" height="315" src="https://www.youtube-nocookie.com/embed/Qgn0aOZRXgc?controls=0"*/}
        {/*  title="YouTube video player" frameBorder="0"*/}
        {/*  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
        {/*/>*/}

        <video
          ref={loopRef}
          className="InactivityVideoLoop_Video"
          autoPlay={true}
          loop={showcaseMode}
          muted={true}
          preload="auto"
          onEnded={handleOnEnd}
          // src="https://brotherants.com/video/0_loop_galerija_Velenje.mp4"
          // src="./media/videos/loop.mp4"
        >
          <source src="https://brotherants.com/video/0_GV_loop.mp4" type="video/mp4" />
        </video>

        {/*<YouTube*/}
        {/*  className={`InactivityVideoLoop_Video`}*/}
        {/*  videoId={'OjkhCo86YIw'}*/}
        {/*  opts={{*/}
        {/*    playerVars: youtubeOptions*/}
        {/*  }}*/}
        {/*/>*/}

        {/*<div className="InactivityVideoLoop_ControlsRoot">*/}
        {/*  <div className="InactivityVideoLoop_Controls">*/}
        {/*    Klikni na video*/}
        {/*  </div>*/}
        {/*</div>*/}

      </div>
    </div>
  );
}

export default InactivityVideoLoop;
